.shadow-inset {
  box-shadow: inset 0 0 4px rgba(#000, .20);
}

.triangle-indicator-wrapper {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 35px 25px 35px;
  border-color: transparent transparent rgba(#000, .08) transparent;
  position: absolute;
  top: -25px;
}


.triangle-indicator {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 34px 25px 34px;
  border-color: transparent transparent #e9f2ff transparent;
  position: absolute;
  left: -34px;
  bottom: -27px;
}


img.grayscaled {
  -webkit-filter: grayscale(75%); /* Safari 6.0 - 9.0 */
  filter: grayscale(75%);
}


footer {
  background-color: #2852a6;
}

footer div {
  color: #ffffff;
  height: 3.5rem;
}

main {
  margin: -3.5rem 0rem;
  min-height: 100vh;
}

button span.errored {
  float: right;
  display: inline;
  margin-top: -2px;
  margin-left: 4px;
}

.drop-zone {
  height: 15rem;
  width: 100%;
}

.screened {
  height: auto;
  overflow-x: auto;
}

.full-screen {
  height: calc(100vh - 16.65rem);
  overflow-y: auto;
}

.full-screen-with-buttons {
  height: calc(100vh - 20.45rem);
  overflow-y: auto;
}

.half-screen {
  height: calc(50vh - 13.55rem);
  overflow-y: auto;
}

.quarter-screen {
  height: calc(25vh - 13.55rem);
  overflow-y: auto;
}

.main-container {
  padding: 6.5rem 3rem !important;
}

.navbar {
  background-color: #2852a6;
}

.partial-screen {
  height: calc(100vh - 28.65rem);
  overflow-y: auto;
}


.nav-tabs {
  border-bottom: 1px solid #fff !important;

  .nav-link {
    color: #2852A6;
    font-weight: 600;
  }

  .nav-link:hover {
    color: darken(#2852A6, 20%)
  }
}

.basic-multi-select .select__control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

table.table .basic-multi-select .select__input-container input:focus {
  border: none !important;
}

.basic-multi-select .select__input-container {
  max-width: 10px !important;
  overflow: hidden !important;
}

.basic-multi-select .select__input-container input {
  border: none !important;
  padding: 0 !important;
  width: 10px !important;
}


.team-information .tab-content {
  border-top: 1px solid #dee2e6;
  padding-top: 1rem;
}

.tab-content .player-details .table thead th {
  min-width: auto !important;
}

/*** PLAYER CARDS ***/

.player-cards .player-card {
  cursor: pointer;
}

.player-cards .player-card:hover {
  background-color: lighten(#e7f1ff, 3%) !important;
  border: 1px solid lighten(#306ffd, 30%);
}

.player-card .card-body {
  padding: 0 0 0.75rem;
  width: auto;
}

.ratio picture {
  overflow: hidden;
}

.ratio picture img {
  width: 100%;
}

.player-card .card-body img {
  //width: 100% !important;
  //max-width: 100% !important;
  //height: auto !important;
}

.player-info {
  padding: .5rem 1.25rem;
}

.player-roster-number {
  right: 10px;
  padding: 2px 4px;
  border-radius: 2px;
  height: 28px !important;
  overflow: hidden;
}

.player-first-name {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding-bottom: 0;
}

.player-last-name {
  font-size: 24px;
  line-height: 24px;
  display: flex;
  margin-top: .25rem;
  margin-bottom: .5rem;
}

.liner {
  border-bottom: 1px solid #4f4f4f;
  width: 5rem;
  height: 2px;
  overflow: hidden;
  font-size: 1px;
  margin-bottom: 1rem;
}

.player-deets {
  margin-bottom: 0;
}


.case-background {
  background-color: #333;
  width: 130px;
  height: 56px;
  padding-top: 5px;
  padding-left: 5px;
}

.case-mp-holder {
  background-color: #797979;
  border: 1px solid #797979;
  width: 20px;
  height: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.case-mp-holder.selected {
  background: #ffffff;
  border: 1px solid #ffffff;

}

/*** TABLES ***/



/*** MOUTHPIECE COLORS ***/

.mouthpiece-colors {
  display: flex;
  margin-bottom: 0.8rem;
}

.color-block {
  width: 24px;
  height: 24px;
  border: 1px solid #FFF;
  display: inline-block;
  margin: 0px 4px 0 4px;
}

// transparent
.aaa {
  background-color: transparent;
  border: 1px solid #ebebeb;
}

// Onyx/Black
.aba {
  background-color: #110f0c;
  border: 1px solid #110f0c;
}

// Crusoe/Dark Green
.aca {
  background-color: #175332;
  border: 1px solid #175332;
}

// Gulf Blue/Dark Blue
.ada {
  background-color: #081259;
  border: 1px solid #081259;
}

// Saphire/Blue
.aea {
  background-color: #0d49bb;
  border: 1px solid #0d49bb;
}

// Navy Blue/Bright Blue
.afa {
  background-color: #1b81d6;
  border: 1px solid #1b81d6;
}

// Spanish Bistro/Dark Gold
.aga {
  background-color: #827536;
  border: 1px solid #827536;
}

// Apple/Neon Green
.aha {
  background-color: #5dca42;
  border: 1px solid #5dca42;
}

// Deep Oak/Brown
.aja {
  background-color: #3e2512;
  border: 1px solid #3e2512;
}

// Bitter Lemon/Neon Yellow
.aka {
  background-color: #d5dd2e;
  border: 1px solid #d5dd2e;
}

// Cyber Grape/Purple
.ala {
  background-color: #563e7a;
  border: 1px solid #563e7a;
}

// Buccaneer/Maroon
.ama {
  background-color: #62302c;
  border: 1px solid #62302c;
}

// Desire/Neon Pink
.apa {
  //background-color: #b22932; // Tall Poppy
  //border: 1px solid #b22932; // Tall Poppy
  background-color: #ef325c; // Desire
  border: 1px solid #ef325c; // Desire
}

// Thunderbird/Red
.ara {
  background-color: #be2f1e;
  border: 1px solid #be2f1e;
}

// Friar Gray/Silver
.asa {
  background-color: #868479;
  border: 1px solid #868479;
}

// Acapulco/Light Turquoise
.ata {
  background-color: #6da599;
  border: 1px solid #6da599;
}

// Sahara/Yellow Gold
.aua {
  background-color: #b8982b;
  border: 1px solid #b8982b;
}

// Sea Green/Green
.ava {
  background-color: #2e9750;
  border: 1px solid #2e9750;
}

// Tiara/White
.awa {
  background-color: #efefef;
  border: 1px solid #b7bcc0;
}

// Flamingo/Orange
.axa {
  background-color: #f15928;
  border: 1px solid #f15928;
}

// Bird Flower/Yellow
.aya {
  background-color: #ccb71e;
  border: 1px solid #ccb71e;
}


#mouthpiece-orders {
  height: auto !important;

}

#mouthpiece-orders .fixed-header {
  overflow-x: auto !important;
}

#mouthpiece-orders .pagination {
  position: relative;
  margin-top: 2rem;
  left: 0;
  bottom: 1rem;
}
