
.table thead th,
.table th,
.table td,
.table thead th,
.table-bordered td,
.table-bordered th,
.table-striped tbody td {
  border-top: none !important;
  border-right: 1px solid #E5E7EB !important;
  border-left: none !important;
  border-bottom: 1px solid #E5E7EB !important;
}

.table thead th {
  min-width: 250px;
  color: #2852A6;
}

.table tr:last-child {
  border-bottom: 1px solid #ffffff !important;
}


table.table-bordered {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
}


table.table select,
table.table input {
  border: 1px solid #E5E7EB !important;
  padding: 4px 8px !important;
  border-radius: 2px !important;
  font-size: 85%;
}

table.table input.filter-field,
table.table select.filter-field {
  height: 36px !important;
  font-size: 16px;
  font-weight: 700;
  color: hsl(0, 0%, 50%);
  padding-right: 4px !important;
}

table.table .input-group-text {
  height: 36px !important;
}

table.table select,
table.table input {
  width: 85% !important;
}

table.table select {
  //font-size: 13px;
  //padding: 2px !important;
}


table.table select:focus,
table.table input:focus {
  border: 2px solid #3184ff !important;
  outline: none;
  font-weight: 600;
  color: #343a40;
}


.table tbody,
.table thead,
.table thead th,
.table thead tr,
.table tbody tr {
  border-top: none !important;
  border-bottom: 1px solid #ffffff !important;
}

.table tbody tr {
  background-color: #ffffff !important;
}

//.table > :not(caption) > * > * {
//  box-shadow: inset 0 0 0 9999px $table-striped-bg;
//}

//.table.table-striped tbody tr:nth-of-type(odd),
//.table-striped > tbody > tr:nth-of-type(odd) > *, {
//  background-color: #FFFFFF !important;
//}
//
.team-information .table.table-striped tbody tr:nth-of-type(odd),
.team-information .table-striped > tbody > tr:nth-of-type(odd) > *, {
  background-color: #FFFFFF !important;
  box-shadow: none !important;
}

//
.team-information .table tbody tr:nth-of-type(even),
.team-information .table > tbody > tr:nth-of-type(even) > *, {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 9999px darken(#e9f2ff, 5%) !important;
}

//.team-information .table tbody tr:nth-of-type(odd),
//.team-information .table.table-striped tbody tr:nth-of-type(odd) {
//  box-shadow: inset 0 0 0 9999px lighten(#2852A6, 75%) !important;
//}


.select__value-container.select__value-container--is-multi.select__value-container--has-value div:first-child {
  width: auto !important;
}

#player-details,
.players,
.mouthpieces,
.faults,
.ddrms,
.ddrc,
.organizations {
  .link,
  .editable-cell {
    text-decoration: underline;
    color: #2852a6 !important;
  }
}

#player-details,
.players,
.mouthpieces,
.faults,
.ddrms,
.ddrc,
.organizations {
  .link:hover,
  .editable-cell div.link:hover {
    cursor: pointer;
    display: block !important;
    text-decoration: none;
    color: #0d6efd;
  }
}



.filter-field {
  height: 1.5rem;
}

.fixed-header table {
  border-collapse: separate;
  border-spacing: 0;
}

//.fixed-header thead tr:nth-child(1) th,
//.fixed-header thead tr:nth-child(2) th,
//.fixed-header thead tr:nth-child(3) th {
//  background-color: #ffffff;
//  height: auto !important;;
//  position: sticky;
//  position: -webkit-sticky;
//  top: 0;
//  z-index: 2;
//}

.fixed-header thead tr:nth-child(1) th:after,
.fixed-header thead tr:nth-child(2) th:after {
  bottom: -2px;
  border-bottom: 1.5px solid #dee2e6;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.fixed-header thead tr:nth-child(1) th:before {
  bottom: -2px;
  border-top: 1.5px solid #dee2e6;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.fixed-header thead tr:nth-child(1) th,
.fixed-header thead tr:nth-child(2) th,
.fixed-header thead tr:nth-child(4) th{
  background-color: #ffffff;
  height: auto !important;
  position: sticky;
  position: -webkit-sticky;
  //top: 2.2rem;
  z-index: 2;
}

.fixed-header thead tr:nth-child(1) th {
  top: 0rem;
}
.fixed-header thead tr:nth-child(2) th {
  top: 2.75rem;
}
.fixed-header thead tr:nth-child(4) th {
  top: 5rem;
}

.fixed-header tbody tr td {
  height: 2.6625rem;
  vertical-align: middle;
}

.players,
.mouthpieces,
.faults,
.ddrms,
.ddrc,
.organizations {
  table tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 100;
    background-color: #FFF;
    border-right-width: 1px !important;
    border-right-color: lighten(#2852a6, 40%) !important;
  }
}

.players,
.mouthpieces,
.faults,
.ddrms,
.ddrc,
.organizations {
  table thead tr:nth-child(2) th:nth-child(1),
  table thead tr:nth-child(4) th:nth-child(1),
  table thead tr:nth-child(2) th:first-child, {
    left: 0;
    position: sticky;
    z-index: 110;
    background-color: #FFF;
    border-right-width: 1px !important;
    border-right-color: lighten(#2852a6, 40%) !important;
  }
}

.players,
.mouthpieces,
.faults,
.ddrms,
.ddrc,
.organizations {
  table thead tr:nth-child(1) th {
    z-index: 1000;
  }
}



.players,
.mouthpieces,
.faults,
.ddrms,
.ddrc,
.organizations {
  table {
    border-left: 1px solid #E5E7EB !important;
    border-right: 1px solid #E5E7EB !important;
  }

  table th {
    border-right: 1px solid #ffffff !important;
    border-bottom: 1px solid #ffffff !important;
  }

  table thead tr:nth-child(3) {
    display: none;
  }

  table thead tr:first-child th {
    border-right: 8px solid #E5E7EB !important;
    font-size: 1.25rem;
    padding-left: 2rem;
  }

  table thead tr:first-child th:last-child,
  table thead tr:nth-child(2) th:last-child,
  table thead tr:nth-child(3) th:last-child,
  table thead tr:nth-child(4) th:last-child {
    border-right: 1px solid #E5E7EB !important;
  }
}

.ddrc {
  table thead th:nth-child(7),
  table tbody td:nth-child(7),
  table thead th:nth-child(9),
  table tbody td:nth-child(9) {
    border-right: 8px solid #E5E7EB !important;
  }
}

.faults {
  table thead th:nth-child(7),
  table tbody td:nth-child(7),
  table thead th:nth-child(10),
  table tbody td:nth-child(10),
  table thead th:nth-child(14),
  table tbody td:nth-child(14) {
    border-right: 8px solid #E5E7EB !important;
  }
}

.ddrms {
  table thead th:nth-child(11),
  table tbody td:nth-child(11),
  table thead th:nth-child(14),
  table tbody td:nth-child(14), {
    border-right: 8px solid #E5E7EB !important;
  }
}

.mouthpieces {
  table thead th:nth-child(8),
  table tbody td:nth-child(8),
  table thead th:nth-child(18),
  table tbody td:nth-child(18),
  table thead th:nth-child(21),
  table tbody td:nth-child(21) {
    border-right: 8px solid #E5E7EB !important;
  }
}

.players {
  table thead th:nth-child(7),
  table tbody td:nth-child(7) {
    border-right: 8px solid #E5E7EB !important;
    border-right: 8px solid #E5E7EB !important;
  }
}

.organizations {
  table thead th:nth-child(4),
  table tbody td:nth-child(4) {
    border-right: 8px solid #E5E7EB !important;
    border-right: 8px solid #E5E7EB !important;
  }
}


.pagination {
  position: fixed;
  left: 3rem;
  bottom: 3.8rem;
  background-color: #fff;
}
